import React from "react";
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

import './Header.css';

import logo from '../../assets/images/logo.webp';
import { Link, useHistory } from "react-router-dom";

function Header(props) {
  // Подписка на контекст
  const currentUser = React.useContext(CurrentUserContext);

  const [homeActive, setHomeActive] = React.useState(false);
  const [articlesActive, setArticlesActive] = React.useState(false);
  const [portfoliosActive, setPortfoliosActive] = React.useState(false);
  const [productsActive, setProductsActive] = React.useState(false);
  const [brandsActive, setBrandsActive] = React.useState(false);
  const [profileLinkActive, setProfileLinkActive] = React.useState(true);

  const history = useHistory();

  React.useEffect(() =>{
    if(history.location.pathname === '/') {
      setHomeActive(true);
    }
    if(history.location.pathname === '/articles') {
      setArticlesActive(true);
    }
    if(history.location.pathname === '/categories') {
      setPortfoliosActive(true);
    }
    if(history.location.pathname === '/brands') {
      setBrandsActive(true);
    }
    if(history.location.pathname === '/profile') {
      setProfileLinkActive(false);
    }
    if(history.location.pathname === '/products') {
      setProductsActive(true);
    }
  },[])

  return(
    <header className="header">
      <div className="header__container">
        <img className="header__logo" src={logo} alt='Логотип'/>
        <nav className="header__nav">
          <Link to="/" className={`header__link ${homeActive && ' header__link_active'}`}>Главная</Link>
          <Link to="/articles" className={`header__link ${articlesActive && ' header__link_active'}`}>Статьи</Link>
          <Link to="/categories" className={`header__link ${portfoliosActive && ' header__link_active'}`}>Категории</Link>
          <Link to="/products" className={`header__link ${productsActive && ' header__link_active'}`}>Товары</Link>
          <Link to="/brands" className={`header__link ${brandsActive && ' header__link_active'}`}>Бренды</Link>
        </nav>
        {
          profileLinkActive &&
        <div className="header__profile-container">
          <p className="header__paragraph">
            Привет,
            <Link to="/profile" className="header__link header__link_profile"> {'****' && currentUser.name}</Link>
          </p>
        </div>
        }
      </div>
    </header>
  )

}

export default Header;