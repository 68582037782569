import React from "react";
import './AddBrand.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';

import mainApi from "../../utils/MainApi";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import { translitUrl } from '../../utils/translitUrl/translitUrl';

import infoOk from '../../assets/images/icons/info_ok.svg';
import infoError from '../../assets/images/icons/info_error.svg';

import Button from "../Button/Button";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

import { HelmetProvider, Helmet } from 'react-helmet-async';

class AddBrand extends React.Component {
  static contextType = EdtiorConfigContext;

  constructor(props){
    super(props);
    this.state = ({
      order: 0,
      title: '',
      description: '',
      preview: '',
      link: '',
    })
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
    this.handleAddCategorySubmit = this.handleAddCategorySubmit.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    // this.onChangeInEditor = this.onChangeInEditor.bind(this);
  }

  // componentDidMount() {
  //   let editorConfig = this.context;
  //   this.setState({
  //     editor: <CKEditor
  //     editor={ Editor }
  //     config={ editorConfig }
  //     data={this.state.editorData}
  //     onReady={ editor => {
  //         // You can store the 'editor' and use when it is needed.
  //         console.log( 'Editor is ready to use!', editor );
  //     } }
  //     onChange={  ( event, editor ) => this.onChangeInEditor(event, editor)  }
  //     onBlur={ ( event, editor ) => {
  //         console.log( 'Blur.', editor );
  //     } }
  //     onFocus={ ( event, editor ) => {
  //         console.log( 'Focus.', editor );
  //     } }
  //     />      
  //   })
  // }

  handleFormReset() {
    this.setState({
      order: 0,
      title: '',
      description: '',
      preview: '',
      link: '',
    })
  }

  handlePreviewChange(e) {
    const data = new FormData();
    data.append('upload', e.target.files[0]);
    mainApi.uploadPreview(data)
    .then((res) => {
      this.setState({
        preview: res.url,
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  // onChangeInEditor(event, editor) {
  //   const data = editor.getData();
  //   this.setState({
  //     editorData: data
  //   })
  // }

  handleValueChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]: value,
    })
    
  }

  /** Отправка формы */

  handleAddCategorySubmit(e) {
    e.preventDefault();
    mainApi.createBrand({
      order: this.state.order,
      title: this.state.title,
      description: this.state.description,
      preview: this.state.preview,
      link: this.state.link
    })
    .then((res) => {
        this.props.openInfoPopup({
          text: `Бренд добавлен`,
          path: '/brands',
          img: infoOk
        });
        this.props.getBrands();
      })
    .catch((err) => {
      this.props.openInfoPopup({
        text: 'Произошла какая то ошибка при добавлении бренда!',
        path: window.location.pathname,
        img: infoError
      });
      console.log(err);
    });
  }


  render() {
    return(
      <>
      <HelmetProvider><Helmet>
      <title>Добавить бренд</title>
    </Helmet></HelmetProvider>
      <Header />
      <main className="add-portfolio">
        <div className="add-portfolio__form-container">
          <h1 className="section__header">Добавить бренд</h1>
          <form className="form add-portfolio__form" id='add-portfolio-form' onSubmit={this.handleAddCategorySubmit} noValidate={true}>

          <div className='form__field form__field_big'>
            <label className='form__label' htmlFor='title'>Название</label>
            <input id='title' className='form__input' required name='title' type='text' placeholder='Название' value={this.state.title} onChange={this.handleValueChange} />
            <span className="title-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='description'>Краткое описание</label>
            <input id='description' className='form__input' required name='description' type='text' placeholder='description' value={this.state.description} onChange={this.handleValueChange} />
            <span className="description-error error-message"></span>
          </div>

          
          <div className='form__field'>
            <label className='form__label form__field_small' htmlFor='link'>Ссылка</label>
            <input id='link' className='form__input' required name='link' type='text' placeholder='link' value={this.state.link} onChange={this.handleValueChange} />
            <span className="link-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='order'>Сортировка</label>
            <input id='order' className='form__input' required name='order' type='number' placeholder='Сортировка' value={this.state.order} onChange={this.handleValueChange} />
            <span className="order-error error-message"></span>
          </div>

          <div className="form__field form__field_small">
            {this.state.preview && <img className='portfolio-editor__image-field' src={this.state.preview} alt='Превью'/>}
          </div>

          <div className='form__field'>
            <label className='form__label form__file-label add-portfolio__file-label' htmlFor='preview'>Превью</label>
            <input id='preview' className='form__input form__file-input' required name='preview' type='file' placeholder='preview' onChange={this.handlePreviewChange}/>
            <span className="preview-error error-message"></span>
          </div>

          <div className='add-article__button-container'>
            <Button type='reset' onClick={this.handleFormReset} className='section__button_transparent add-article__button add-article__form-reset-button' disabled={false}>
              Сбросить
            </Button>
            <Button type='submit' className='form__button' disabled={false}>
              Сохранить
            </Button>
          </div>

          </form>
        </div>
        <InfoTooltip
        isDone={this.props.isDone}
        handleCloseInfoPopup={this.props.handleCloseInfoPopup}
        path={this.props.infoData.path}
        img={this.props.infoData.img}
        text={this.props.infoData.text}
      />
      </main>
      <Footer />
      </>

      )
  }
}

export default AddBrand;