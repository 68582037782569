import React from "react";
import './Categories.css';
import mainApi from '../../utils/MainApi';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PortfolioCard from "../PortfolioCard/PortfolioCard";

import PopupWIthForm from "../PopupWIthForm/PopupWIthForm";

import InfoMessage from "../InfoMessage/InfoMessage";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import CategoryCard from "../CategoryCard/CategoryCard";

class Categories extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      popupWithFormOpened: false,
      selectedPortfolio: {},
      isShowInfoMessage: false,
      infoMessageConfig: {
        isShowInfoMessage: false,
        responseState: '',
        text: '',
      }
    }
    this.handleSelectPortfolio = this.handleSelectPortfolio.bind(this);
    this.handlePopupFormSubmit = this.handlePopupFormSubmit.bind(this);
    this.handleClosePopupWithForm = this.handleClosePopupWithForm.bind(this);
    this.handlePopupWithFormOpen = this.handlePopupWithFormOpen.bind(this);
    this.showInfoMessageTimer = this.showInfoMessageTimer.bind(this);
    this.closeInfoMessage = this.closeInfoMessage.bind(this);
  }

  handleSelectPortfolio(portfolio) {
    this.setState({
      selectedPortfolio: portfolio,
    })
  }

  handlePopupWithFormOpen() {
    this.setState({
      popupWithFormOpened: true,
    });
  }

  handlePopupFormSubmit(e) {
    e.preventDefault();
    mainApi.deleteCategory(this.state.selectedPortfolio)
    .then((res) =>{
      if(res) {
        this.handleClosePopupWithForm();
        this.setState({
          infoMessageConfig: {
            isShowInfoMessage: true,
            responseState: 'info-message_positive',
            text: 'Категория успешно удалена!',
          }
        });
        this.showInfoMessageTimer();
        this.props.getCategories();
        this.props.getProducts();
      }
    })
    .catch((err) => {
      console.log(err);
      this.handleClosePopupWithForm();
      this.setState({
        infoMessageConfig: {
          isShowInfoMessage: true,
          responseState: 'info-message_negative',
          text: 'Произошла внутренняя ошибка.',
        }
      });
    })
  }

  handleClosePopupWithForm(e) {
    this.setState({
      popupWithFormOpened: false,
    })
  }

  closeInfoMessage() {
    this.setState({
      infoMessageConfig: {
        isShowInfoMessage: false,
      }
    });
    clearTimeout(this.timer);
  }

  // RULES OF INFO MESSAGE

  //Установка таймера
  showInfoMessageTimer() {
    this.timer = setTimeout(() => {
      this.closeInfoMessage();
    }, 5000);
  }

  //** END RULES OF INFO MESSAGE */




  render() {
    return(
      <>
      <HelmetProvider><Helmet>
      <title>Все категории</title>
    </Helmet></HelmetProvider>
      <Header />
      <main className="section portfolios">
        <div className="section__container portfolios__section-container">
        <h1 className='section__header'>Все категории <span className="header__count">{this.props.categoriesData.length}</span></h1>
          <div className="portfolios__list">
            {
              !this.props.categoriesData.length > 0 ?
              <>
                <div className="portfolio__card_current"></div>
                <div className="portfolio__card_current"></div>
                <div className="portfolio__card_current"></div>
              </>
              :
              this.props.categoriesData.map((category) => (
                <CategoryCard isVisibile={category.isVisible} key={category._id} {...category} handleSelectPortfolio={this.handleSelectPortfolio} handlePopupWithFormOpen={this.handlePopupWithFormOpen} />
                ))
            }
            <Link to="/add-category" className="portfolios__add-link">Добавить категорию</Link>
          </div>
        </div>
      </main>
      <PopupWIthForm
      popupName="portfolio__confirm-delete"
      isOpen={this.state.popupWithFormOpened}
      title="Удалить категорию?"
      onSubmit={this.handlePopupFormSubmit}
      onClose={this.handleClosePopupWithForm}>
        <div className="portfolio__confirm-delete-buttons-container">
            <button type="button" onClick={this.handleClosePopupWithForm} className="portfolio__confirm-delete-button portfolio__confirm-delete-button_negative">Нет</button>
            <button type="submit" className="portfolio__confirm-delete-button portfolio__confirm-delete-button_positive">Да</button>
        </div>
      </PopupWIthForm>
      <InfoMessage infoMessageConfig={this.state.infoMessageConfig} closeInfoMessage={this.closeInfoMessage} />
      <Footer />
      </>
      
    )
  }
}

export default Categories;