import React from "react";
import './Profile.css';

import { CurrentUserContext } from "../../contexts/CurrentUserContext";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Button from "../Button/Button";

import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useHistory } from "react-router-dom";

function Profile(props) {
  const currentUser = React.useContext(CurrentUserContext);
  const history = useHistory();

  function handleLogOut() {
    localStorage.removeItem('jwt');
    history.push('/');
    props.setLoggedOut();
  }

  return(
    <>
    <HelmetProvider><Helmet>
      <title>Профиль</title>
    </Helmet></HelmetProvider>
    <Header />
    <main className="section profile">
      <div className="section__container profile__container">
        <h1 className="profile__name">Привет, {currentUser.name}!</h1>
        <div className="profile__info-container">
          <div className="profile__item">
            <p className="profile__paragraph">Твоё имя:</p>
            <div className="profile__item-line"></div>
            <p className="profile__info-value">{currentUser.name}</p>
          </div>
          <div className="profile__item">
            <p className="profile__paragraph">Твоя почта:</p>
            <div className="profile__item-line"></div>
            <p className="profile__info-value">{currentUser.email}</p>
          </div>
        </div>
        <div className="profile__info-button-container">
          <Button type='button' onClick={handleLogOut} className='section__button profile__info-button' disabled={false}>
            Выйти
          </Button>
        </div>
      </div>
    </main>
    <Footer />
    </>
  )

}

export default Profile;