import React from "react";
import './ProductEditor.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';

import mainApi from "../../utils/MainApi";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import { translitUrl } from '../../utils/translitUrl/translitUrl';

import infoOk from '../../assets/images/icons/info_ok.svg';
import infoError from '../../assets/images/icons/info_error.svg';

import Button from "../Button/Button";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

import { HelmetProvider, Helmet } from 'react-helmet-async';

class ProductEditor extends React.Component {
  static contextType = EdtiorConfigContext;
  
  constructor(props){
    super(props);
    this.product = this.props.productsData.find(product => product._id === this.props.computedMatch.params.productId);
    this.state = ({
      order: this.product.order,
      category: this.product.category._id,
      mark: this.product.mark,
      shopUrl: this.product.shopUrl,
      additionalCategories: this.product.additionalCategories,
      isVisible: this.product.isVisible,
      brand: this.product.brand[0]._id,
      url: this.product.url,
      title: this.product.title,
      description: this.product.description,
      preview: this.product.preview,
      metaTitle: this.product.metaTitle,
      metaDescription: this.product.metaDescription,
      editor: null,
      editorData: this.product.htmlCode,
      formReset: false,
      isCategoryChanged: false,
    })
    
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
    this.handleAddPortfolioSubmit = this.handleAddPortfolioSubmit.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.onChangeInEditor = this.onChangeInEditor.bind(this);
  }

  componentDidUpdate() {
    if(this.state.formReset) {
      this._setEditors();
      this.setState({
        formReset: false
      })
    }
  }

  componentDidMount() {
    this._setEditors();
  }

  _setEditors() {
    let editorConfig = this.context;
    this.setState({
      editor: <CKEditor
      editor={ Editor }
      config={ editorConfig }
      data={this.state.editorData}
      onReady={ editor => {
          // You can store the 'editor' and use when it is needed.
          console.log( 'Editor is ready to use!', editor );
      } }
      onChange={  ( event, editor ) => this.onChangeInEditor(event, editor)  }
      onBlur={ ( event, editor ) => {
          console.log( 'Blur.', editor );
      } }
      onFocus={ ( event, editor ) => {
          console.log( 'Focus.', editor );
      } }
      />
    })
  }

  handleFormReset() {
    this.setState({
      order: 0,
      title: '',
      description: '',
      preview: '',
      url: '',
      client: '',
      format: '',
      price: '',
      metaTitle: '',
      metaDescription: '',
      formReset: true,
    })
  }

  handlePreviewChange(e) {
    const data = new FormData();
    data.append('upload', e.target.files[0]);
    mainApi.uploadPreview(data)
    .then((res) => {
      this.setState({
        preview: res.url,
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  onChangeInEditor(event, editor) {
    const data = editor.getData();
    this.setState({
      editorData: data
    })
  }

  handleValueChange(e) {
    const {name, value} = e.target;
    if(name === 'title') {
      this.setState({
        title: value,
        url: translitUrl(value),
      })
    } else if(name === 'url') {
      this.setState({
        url: value,
      })
    } else if(name === 'category') {
      this.setState({
        category: value,
        isCategoryChanged: true,
      })
    } else {
      this.setState({
        [name]: value,
      })
    }
    
  }

  /** Отправка формы */

  handleAddPortfolioSubmit(e) {
    e.preventDefault();
    mainApi.updateProduct({
      _id: this.product._id,
      order: this.state.order,
      category: this.state.category,
      mark: this.state.mark,
      shopUrl: this.state.shopUrl,
      additionalCategories: this.state.additionalCategories,
      isVisible: this.state.isVisible,
      brand: [this.state.brand],
      url: this.state.url,
      title: this.state.title,
      description: this.state.description,
      preview: this.state.preview,
      htmlCode: this.state.editorData,
      metaTitle: this.state.metaTitle,
      metaDescription: this.state.metaDescription
    })
    .then((res) => {
      if(this.state.isCategoryChanged) {
      
        mainApi.updateCategoryProducts(
          this.product.category._id,
          this.product.category.products.filter(p => p !== this.product._id)
        )
        .then((res) => {
          const category = this.props.categoriesData.filter(p => p._id === this.state.category)[0];
          console.log(category)
          mainApi.updateCategoryProducts(
            category._id,
            category.products.concat(this.product._id)
          )
          .then((res) => {
            this.props.openInfoPopup({
              text: 'Товар изменен!',
              path: '/products',
              img: infoOk
            });
            this.props.getProducts();
            this.props.getCategories();
          })
        })
      }
      this.props.openInfoPopup({
        text: 'Товар изменен!',
        path: '/products',
        img: infoOk
      });
      this.props.getProducts();
      this.props.getCategories();
    })
    .catch((err) => {
      console.log(err);
      this.props.openInfoPopup({
        text: 'Произошла какая то ошибка!',
        path: window.location.pathname,
        img: infoError
      });
    });
  }


  render() {
    return(
      <>
      <HelmetProvider><Helmet>
      <title>Редактировать товар</title>
    </Helmet></HelmetProvider>
      <Header />

      <main className="portfolio-editor">
        <div className="portfolio-editor__form-container">
          <h1 className="section__header">Редактировать товар</h1>

          <form className="form portfolio-editor__form" id='portfolio-editor-form' onSubmit={this.handleAddPortfolioSubmit} noValidate={true}>

          <div className='form__field form__field_big'>
            <label className='form__label' htmlFor='title'>Название</label>
            <input id='title' className='form__input' required name='title' type='text' placeholder='Название' value={this.state.title} onChange={this.handleValueChange} />
            <span className="title-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='description'>Краткое описание</label>
            <input id='description' className='form__input' required name='description' type='text' placeholder='description' value={this.state.description} onChange={this.handleValueChange} />
            <span className="description-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='category'>Родительская категория</label>
            <select className='form__input' value={this.state.category} onChange={this.handleValueChange} multiple={false} id="category" name="category">
              {
                this.props.categoriesData.map(c => <option value={c._id} key={c._id}>{`${c.title}(${Array.isArray(c.parentCategory) && c.parentCategory.length > 0 ? c.parentCategory[0].title : 'Каталог'})`}</option>)
              }
            </select>
            <span className="category-error error-message"></span>
          </div>

          <div className='form__field'>
            <label className='form__label form__field_small' htmlFor='url'>URL</label>
            <input id='url' className='form__input' required name='url' type='text' placeholder='url' value={this.state.url} onChange={this.handleValueChange} />
            <span className="url-error error-message"></span>
          </div>

          <div className='form__field'>
            <label className='form__label form__field_small' htmlFor='shopUrl'>Ссылка в итернет-магазине</label>
            <input id='shopUrl' className='form__input' required name='shopUrl' type='text' placeholder='shopUrl' value={this.state.shopUrl} onChange={this.handleValueChange} />
            <span className="shopUrl-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='mark'>Маркировка</label>
            <select className='form__input' value={this.state.mark} onChange={this.handleValueChange} multiple={false} id="mark" name="mark">
              <option value='none'>Обычный</option>
              <option value='new'>Новинка</option>
              <option value='special'>Спецпредложение</option>
              <option value='sale'>Распродажа</option>
            </select>
            <span className="mark-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='isVisible'>Видимость</label>
            <select className='form__input' value={this.state.isVisible} onChange={this.handleValueChange} multiple={false} id="isVisible" name="isVisible">
              <option value={true}>Видимый</option>
              <option value={false}>Скрыть</option>
            </select>
            <span className="isVisible-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='order'>Сортировка</label>
            <input id='order' className='form__input' required name='order' type='number' placeholder='Сортировка' value={this.state.order} onChange={this.handleValueChange} />
            <span className="order-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='category'>Бренд</label>
            <select className='form__input' value={this.state.brand} onChange={this.handleValueChange} multiple={false} id="brand" name="brand">
              {
                this.props.brandsData.map(b => <option value={b._id} key={b._id}>{b.title}</option>)
              }
            </select>
            <span className="brand-error error-message"></span>
          </div>

          <div className="form__field form__field_small">
            <img className='portfolio-editor__image-field' src={this.state.preview} alt='Превью'/>
          </div>

          <div className='form__field'>
            <div>
              <label className='form__label form__file-label portfolio-editor__file-label' htmlFor='preview'>Превью</label>
              <input id='preview' className='form__input form__file-input' required name='preview' type='file' placeholder='preview' onChange={this.handlePreviewChange}/>
              <span className="preview-error error-message"></span>
            </div>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaTitle'>Мета-title</label>
            <textarea id='metaTitle' className='form__textarea' required name='metaTitle' placeholder='metaTitle' value={this.state.metaTitle} onChange={this.handleValueChange} />
            <span className="metaTitle-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaDescription'>Мета-description</label>
            <textarea id='metaDescription' className='form__textarea' required name='metaDescription' placeholder='metaDescription' value={this.state.metaDescription} onChange={this.handleValueChange} />
            <span className="metaDescription-error error-message"></span>
          </div>

          <div className='ck-editor__field'>
            <label className='form__label portfolio-editor__form-label'>Описание</label>
            {this.state.editor}
          </div>
          <div className='add-article__button-container'>
            <Button type='reset' onClick={this.handleFormReset} className='section__button_transparent add-article__button add-article__form-reset-button' disabled={false}>
              Сбросить
            </Button>
            <Button type='submit' className='form__button' disabled={false}>
              Сохранить
            </Button>
          </div>

          </form>
        </div>
        <InfoTooltip
        isDone={this.props.isDone}
        handleCloseInfoPopup={this.props.handleCloseInfoPopup}
        path={this.props.infoData.path}
        img={this.props.infoData.img}
        text={this.props.infoData.text}
      />
      </main>

      <Footer />
      </>

      )
  }
}

export default ProductEditor;