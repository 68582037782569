import React from "react";
import './Articles.css';
import mainApi from '../../utils/MainApi';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ArticleCard from "../ArticleCard/ArticleCard";

import PopupWIthForm from "../PopupWIthForm/PopupWIthForm";

import InfoMessage from "../InfoMessage/InfoMessage";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from 'react-helmet-async';

class Articles extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      popupWithFormOpened: false,
      selectedArticle: {},
      isShowInfoMessage: false,
      infoMessageConfig: {
        isShowInfoMessage: false,
        responseState: '',
        text: '',
      }
    }
    this.handleSelectArticle = this.handleSelectArticle.bind(this);
    this.handlePopupFormSubmit = this.handlePopupFormSubmit.bind(this);
    this.handleClosePopupWithForm = this.handleClosePopupWithForm.bind(this);
    this.handlePopupWithFormOpen = this.handlePopupWithFormOpen.bind(this);
    this.showInfoMessageTimer = this.showInfoMessageTimer.bind(this);
    this.closeInfoMessage = this.closeInfoMessage.bind(this);
  }

  handleSelectArticle(article) {
    this.setState({
      selectedArticle: article,
    })
  }

  handlePopupWithFormOpen() {
    this.setState({
      popupWithFormOpened: true,
    });
  }

  handlePopupFormSubmit(e) {
    e.preventDefault();
    mainApi.deleteArticle(this.state.selectedArticle)
    .then((res) =>{
      if(res) {
        this.handleClosePopupWithForm();
        this.setState({
          infoMessageConfig: {
            isShowInfoMessage: true,
            responseState: 'info-message_positive',
            text: 'Статья успешно удалена!',
          }
        });
        this.showInfoMessageTimer();
        this.props.getArticles();
      }
    })
    .catch((err) => {
      console.log(err);
      this.handleClosePopupWithForm();
      this.setState({
        infoMessageConfig: {
          isShowInfoMessage: true,
          responseState: 'info-message_negative',
          text: 'Произошла внутренняя ошибка.',
        }
      });
    })
  }

  handleClosePopupWithForm(e) {
    this.setState({
      popupWithFormOpened: false,
    })
  }

  closeInfoMessage() {
    this.setState({
      infoMessageConfig: {
        isShowInfoMessage: false,
      }
    });
    clearTimeout(this.timer);
  }

  // RULES OF INFO MESSAGE

  //Установка таймера
  showInfoMessageTimer() {
    this.timer = setTimeout(() => {
      this.closeInfoMessage();
    }, 5000);
  }

  //** END RULES OF INFO MESSAGE */




  render() {
    return(
      <>
      <HelmetProvider><Helmet>
      <title>Все статьи</title>
    </Helmet></HelmetProvider>
      <Header />
      <main className="section articles">
        <div className="section__container articles__section-container">
          <h1 className='section__header'>Все статьи <span className="header__count">{this.props.articlesData.length}</span></h1>
          <div className="articles__list">
            {
              !this.props.articlesData.length > 0 ?
              <>
                <div className="article__card_current"></div>
                <div className="article__card_current"></div>
                <div className="article__card_current"></div>
              </>
              :
              this.props.articlesData.reverse().map((article) => (
                <ArticleCard key={article._id} {...article} handleSelectArticle={this.handleSelectArticle} handlePopupWithFormOpen={this.handlePopupWithFormOpen} />
                ))
            }
            <Link to="/add-article" className="articles__add-link">Добавить статью</Link>
          </div>
        </div>
      </main>
      <PopupWIthForm
      popupName="article__confirm-delete"
      isOpen={this.state.popupWithFormOpened}
      title="Удалить статью?"
      onSubmit={this.handlePopupFormSubmit}
      onClose={this.handleClosePopupWithForm}>
        <div className="article__confirm-delete-buttons-container">
            <button type="button" onClick={this.handleClosePopupWithForm} className="article__confirm-delete-button article__confirm-delete-button_negative">Нет</button>
            <button type="submit" className="article__confirm-delete-button article__confirm-delete-button_positive">Да</button>
        </div>
      </PopupWIthForm>
      <InfoMessage infoMessageConfig={this.state.infoMessageConfig} closeInfoMessage={this.closeInfoMessage} />
      <Footer />
      </>
      
    )
  }
}

export default Articles;