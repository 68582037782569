import React from "react";
import { Link } from "react-router-dom";

import './Home.css';

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Button from "../Button/Button";
import { HelmetProvider, Helmet } from 'react-helmet-async';

function Home(props) {
  
  return (
    <>
    <HelmetProvider><Helmet>
      <title>Панель управления — АО Арника</title>
    </Helmet></HelmetProvider>
    <Header/>
    <main className="section home">
      <div className="section__container home__container">

        <div className="home__info-container">
          
          <div className="home__info">
            <h1 className="home__info-title">Сводка</h1>
            <div className="home__card">
              <div className="home__card-image home__card-image_articles"></div>
              <h2 className="home__card-value">{props.articlesData.length}</h2>
              <h4 className="home__card-title">Статьи</h4>
              <Link to='/articles' className='section__button_transparent home__button_transparent'>Управлять</Link>
            </div>
            <div className="home__card">
              <div className="home__card-image home__card-image_portfolios"></div>
              <h2 className="home__card-value">{props.categoriesData.length}</h2>
              <h4 className="home__card-title">Категории</h4>
              <Link to='/categories' className='section__button_transparent home__button_transparent'>Управлять</Link>
            </div>
            <div className="home__card">
              <div className="home__card-image home__card-image_products"></div>
              <h2 className="home__card-value">{props.productsData.length}</h2>
              <h4 className="home__card-title">Товары</h4>
              <Link to='/products' className='section__button_transparent home__button_transparent'>Управлять</Link>
            </div>
            <div className="home__card">
              <div className="home__card-image home__card-image_brands"></div>
              <h2 className="home__card-value">{props.brandsData.length}</h2>
              <h4 className="home__card-title">Бренды</h4>
              <Link to='/brands' className='section__button_transparent home__button_transparent'>Управлять</Link>
            </div>
          </div>

        </div>
      </div>
    </main>
    <Footer/>
    </>
  )
};

export default Home;