import React from "react";
import './BrandCard.css';


import editIcon from '../../assets/images/icons/editing.png';
import trashIcon from '../../assets/images/icons/trash.png';
import { useHistory } from "react-router-dom";

function BrandCard(brand) {
  const history = useHistory();

  function handleBrandEdit() {
    history.push(`/brands/${brand._id}/edit`)
  }

  function handleBrandDelete() {
    brand.handlePopupWithFormOpen();
    brand.handleSelectBrand(brand);
  }
  
  return (
        <article className={`portfolio-card ${!brand.isVisible && 'hidden'}`}>
          <div className="portfolio-card__info-container">
            <img className="portfolio-card__image" src={brand.preview} alt="Превью" />
            <h3>{brand.title}</h3>
          </div>
          <div className="portfolio-card__button-container">
            <button onClick={handleBrandEdit} className="portfolio-card__button portfolio-card__button_edit">
              <img src={editIcon} alt="Редактировать портфолио" />
            </button>
            <button onClick={handleBrandDelete}  type="button" className="portfolio-card__button portfolio-card__button_delete">
              <img src={trashIcon} alt="Удалить портфолио" />
            </button>
          </div>
        </article>
)
}

export default BrandCard;