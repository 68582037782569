class MainApi {
  constructor(options) {
    this._baseUrl = options.baseUrl;
  }

  _checkResponse(res) {
    if (!res.ok) {
        return Promise.reject(`Ошибка: ${res.status}`);
    }
    return res.json();
  }

  register(name, password, email) {
    return fetch(`${this._baseUrl}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "name": name,
        "password": password,
        "email": email })
      })
    .then(res => {
      return this._checkResponse(res);
    })};

  authorize(password, email) {
    return fetch(`${this._baseUrl}/signin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "password": password,
        "email": email
      })
    })
    .then(res => {
      return this._checkResponse(res);
    })};

  getContent(token) {
    return fetch(`${this._baseUrl}/users/me`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${token}`
      },
      withCredentials: true,
    })
    .then(res => {
      return this._checkResponse(res);
    })
  };

  uploadPreview(preview) {
      return fetch(`${this._baseUrl}/upload`, {
        // content-type header should not be specified!
        method: 'POST',
        body: preview,
      }).then(res => {
        return this._checkResponse(res);
      })
  };



  getArticles() {
    return fetch(`${this._baseUrl}/articles`, {
      // content-type header should not be specified!
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => {
      return this._checkResponse(res);
    })
  };

  createArticle(articleData) {
    return fetch(`${this._baseUrl}/articles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        "isVisible": articleData.isVisible,
        "category": articleData.category,
        "title": articleData.title,
        "description": articleData.description,
        "preview": articleData.preview,
        "url": articleData.url,
        "tags": articleData.tags,
        "htmlCode": articleData.htmlCode,
        "metaTitle": articleData.metaTitle,
        "metaDescription": articleData.metaDescription,
      })
      })
    .then(res => {
      return this._checkResponse(res);
    })};

    updateArticle(articleData) {
      return fetch(`${this._baseUrl}/articles/${articleData.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          "isVisible": articleData.isVisible,
          "category": articleData.category,
          "title": articleData.title,
          "description": articleData.description,
          "preview": articleData.preview,
          "url": articleData.url,
          "tags": articleData.tags,
          "htmlCode": articleData.htmlCode,
          "metaTitle": articleData.metaTitle,
          "metaDescription": articleData.metaDescription,
        })
        })
      .then(res => {
        return this._checkResponse(res);
      })};

  deleteArticle(article) {
    return fetch(`${this._baseUrl}/articles/${article._id}`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
      }
    })
    .then(res => {
      return this._checkResponse(res);
    })};

    getProducts() {
      return fetch(`${this._baseUrl}/products`, {
        // content-type header should not be specified!
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(res => {
        return this._checkResponse(res);
      })
    };
  
    createProduct(productData) {
      return fetch(`${this._baseUrl}/products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          "order": productData.order,
          "category": productData.category,
          "mark": productData.mark,
          "shopUrl": productData.shopUrl,
          "additionalCategories": productData.additionalCategories,
          "isVisible": productData.isVisible,
          "brand": productData.brand,
          "url": productData.url,
          "title": productData.title,
          "description": productData.description,
          "preview": productData.preview,
          "htmlCode": productData.htmlCode,
          "metaTitle": productData.metaTitle,
          "metaDescription": productData.metaDescription,
        })
        })
      .then(res => {
        return this._checkResponse(res);
      })};
  
      updateProduct(productData) {
        return fetch(`${this._baseUrl}/products/${productData._id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          },
          body: JSON.stringify({
            "order": productData.order,
            "category": productData.category,
            "mark": productData.mark,
            "shopUrl": productData.shopUrl,
            "additionalCategories": productData.additionalCategories,
            "isVisible": productData.isVisible,
            "brand": productData.brand,
            "url": productData.url,
            "title": productData.title,
            "description": productData.description,
            "preview": productData.preview,
            "htmlCode": productData.htmlCode,
            "metaTitle": productData.metaTitle,
            "metaDescription": productData.metaDescription,
            })
          })
        .then(res => {
          return this._checkResponse(res);
        })};
  
    deleteProduct(product) {
      return fetch(`${this._baseUrl}/products/${product._id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        }
      })
      .then(res => {
        return this._checkResponse(res);
      })};

    getCategories() {
      return fetch(`${this._baseUrl}/categories`, {
        // content-type header should not be specified!
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(res => {
        return this._checkResponse(res);
      })
    };

    createCategory(categoryData) {
      return fetch(`${this._baseUrl}/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          "order": categoryData.order,
          "parentCategory": categoryData.parentCategory,
          "additionalCategories": categoryData.additionalCategories,
          "products": categoryData.products,
          "isVisible": categoryData.isVisible,
          "brand": categoryData.brand,
          "url": categoryData.url,
          "title": categoryData.title,
          "description": categoryData.description,
          "preview": categoryData.preview,
          "metaTitle": categoryData.metaTitle,
          "metaDescription": categoryData.metaDescription
        })
        })
      .then(res => {
        return this._checkResponse(res);
      })};

      updateCategory(categoryData) {
        return fetch(`${this._baseUrl}/categories/${categoryData._id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          },
          body: JSON.stringify({
            "order": categoryData.order,
            "parentCategory": categoryData.parentCategory,
            "additionalCategories": categoryData.additionalCategories,
            "products": categoryData.products,
            "isVisible": categoryData.isVisible,
            "brand": categoryData.brand,
            "url": categoryData.url,
            "title": categoryData.title,
            "description": categoryData.description,
            "preview": categoryData.preview,
            "metaTitle": categoryData.metaTitle,
            "metaDescription": categoryData.metaDescription
          })
          })
        .then(res => {
          return this._checkResponse(res);
        })};

        updateCategoryProducts(categoryId, products) {
          return fetch(`${this._baseUrl}/categories/${categoryId}/products`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          },
          body: JSON.stringify({
            "products": products,
            })
          })
          .then(res => {
            return this._checkResponse(res);
          })};

          updateCategoryParentCategory(categoryId, parentCategory) {
            return fetch(`${this._baseUrl}/categories/${categoryId}/parentCategory`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
            },
            body: JSON.stringify({
              "parentCategory": parentCategory,
              })
            })
            .then(res => {
              return this._checkResponse(res);
            })};

            updateCategoryAdditional(categoryId, additionalCategories) {
              return fetch(`${this._baseUrl}/categories/${categoryId}/additional`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
              },
              body: JSON.stringify({
                "additionalCategories": additionalCategories,
                })
              })
              .then(res => {
                return this._checkResponse(res);
              })};

      deleteCategory(category) {
        return fetch(`${this._baseUrl}/categories/${category._id}`, {
          method: 'DELETE',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
          }
        })
        .then(res => {
          return this._checkResponse(res);
        })};

        getBrands() {
          return fetch(`${this._baseUrl}/brands`, {
            // content-type header should not be specified!
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          }).then(res => {
            return this._checkResponse(res);
          })
        };
    
        createBrand(brandData) {
          return fetch(`${this._baseUrl}/brands`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
            },
            body: JSON.stringify({
              "order": brandData.order,
              "title": brandData.title,
              "description": brandData.description,
              "preview": brandData.preview,
              "link": brandData.link
            })
            })
          .then(res => {
            return this._checkResponse(res);
          })};
    
          updateBrand(brandData) {
            return fetch(`${this._baseUrl}/brands/${brandData._id}`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
              },
              body: JSON.stringify({
                "order": brandData.order,
                "title": brandData.title,
                "description": brandData.description,
                "preview": brandData.preview,
                "link": brandData.link
              })
              })
            .then(res => {
              return this._checkResponse(res);
            })};

            deleteBrand(brand) {
              return fetch(`${this._baseUrl}/brands/${brand._id}`, {
                method: 'DELETE',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
                }
              })
              .then(res => {
                return this._checkResponse(res);
              })};


        getParentCategory() {
          return fetch(`${this._baseUrl}/parentCategory`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          }).then(res => {
            return this._checkResponse(res);
          })
        };


        updateParentCategory(parentCategoryData) {
          return fetch(`${this._baseUrl}/parentCategory/63f3b0f935f7dfddcb92114d`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${localStorage.getItem('jwt')}`
            },
            body: JSON.stringify({
              "additionalCategories": parentCategoryData.additionalCategories,
              "title": parentCategoryData.title,
              "description": parentCategoryData.description,
              "metaTitle": parentCategoryData.metaTitle,
              "metaDescription": parentCategoryData.metaDescription
            })
            })
          .then(res => {
            return this._checkResponse(res);
          })};
}


const mainApi = new MainApi({
  baseUrl: 'https://api.arnika-med.ru',
  // baseUrl: 'http://localhost:3001',
});

export default mainApi;