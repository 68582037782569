import React from 'react';
import { Route, Switch, Link, withRouter, useHistory } from "react-router-dom";
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import mainApi from '../../utils/MainApi';

import Login from '../Login/Login';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import Home from '../Home/Home';
import AddArticle from '../AddArticle/AddArticle';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Articles from '../Articles/Articles';
import ArticleEditor from '../ArticleEditor/ArticleEditor';
import Categories from '../Categories/Categories';
import AddCategory from '../AddCategory/AddCategory';
import CategoryEditor from '../CategoryEditor/CategoryEditor';
import Profile from '../Profile/Profile';
import Products from '../Products/Products';
import ProductEditor from '../ProductEditor/ProductEditor';
import AddProduct from '../AddProduct/AddProduct';
import Brands from '../Brands/Brands';
import AddBrand from '../AddBrand/AddBrand';
import BrandEditor from '../BrandEditor/BrandEditor';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editorConfiguration: {
        removePlugins: ["MediaEmbedToolbar", "Title", "Markdown"],
        simpleUpload: {
          uploadUrl: `${mainApi._baseUrl}/upload`,
      }},
      editorData: '',
      editorPortfolioData: '',
      overviewData: '',
      executionData: '',
      resultData: '',
      preview: '',
      previewPortfolio: '',
      miniPreview: '',
      infoData: {
        path: "",
        img: "",
        text: ""
      },
      isDone: false,
      currentUser: {},
      isLoggedIn: false,
      location: this.props.history.location.pathname,
      articlesData: [{}],
      categoriesData: [{}],
      productsData: [{}],
      brandsData: [{}],
      parentCategoryData: [{}],
      errorVisible: false,
      textError: '',
    }
    // this.history = useHistory();
    this.tokenCheck = this.tokenCheck.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.getParentCategory = this.getParentCategory.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.openInfoPopup = this.openInfoPopup.bind(this);
    this.handleCloseInfoPopup = this.handleCloseInfoPopup.bind(this);
    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.setLoggedIn = this.setLoggedIn.bind(this);
    this.setLoggedOut = this.setLoggedOut.bind(this);
  }
  /** */

  componentDidMount() {
    this.getBrands();
    this.getParentCategory();
    this.getArticles();
    this.getCategories();
    this.getProducts();
    this.tokenCheck();
  }

  openInfoPopup(data) {
    this.setState({
      infoData: {
        path: data.path,
        img: data.img,
        text: data.text,
      },
      isDone: true,
    });
  }

  handleCloseInfoPopup() {
    this.setState({
      setInfoData: {
        path: "",
        img: "",
        text: ""
      },
      isDone: false,
    })
  }

  handleSubmitRegister(name, password, email) {
    console.log(name, password, email)
    return  mainApi.register(name, password, email)
    .then((data) => {
      return data
    })
    .catch(err => console.log(err));
  }

  handleSubmitLogin(email, password) {
    return  mainApi.authorize(password, email)
    .then((data) => {
      this.setState({
        errorVisible: false,
        textError: '',
      })
      return data
    })
    .catch((err) => {
      if(err === 'Ошибка: 401') {
        this.setState({
          errorVisible: true,
          textError: 'Неправильные почта или пароль!',
        })
      } else {
        this.setState({
          errorVisible: true,
          textError: 'Произошла ошибка на сервере. Повторите позднее.',
        })
      }
      console.log(err)
    });
  }

  tokenCheck() {
    const jwt = localStorage.getItem('jwt');
    if (jwt){
      mainApi.getContent(jwt)
      .then((res) => {
        if (res){
          this.setLoggedIn();
          this.setState({
            currentUser: res,
          })
          console.log()
          this.props.history.push(this.state.location);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  getParentCategory() {
    mainApi.getParentCategory().then((res) => {
      if(res) {
        this.setState({
          parentCategoryData: res,
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  getArticles() {
    mainApi.getArticles().then((res) => {
      if(res) {
        this.setState({
          articlesData: res.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  getBrands() {
    mainApi.getBrands().then((res) => {
      if(res) {
        this.setState({
          brandsData: res.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  };
  getCategories() {
    mainApi.getCategories().then((res) => {
      if(res) {
        this.setState({
          categoriesData: res.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  getProducts() {
    mainApi.getProducts().then((res) => {
      if(res) {
        this.setState({
          productsData: res.reverse(),
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  setLoggedIn() {
    this.setState({
      isLoggedIn: true,
    })
  }

  setLoggedOut() {
    this.setState({
      isLoggedIn: false,
    })
  }

   render() {
        return (
          <CurrentUserContext.Provider value={this.state.currentUser}>
            <EdtiorConfigContext.Provider value={this.state.editorConfiguration}>
            
            <Switch>
              <ProtectedRoute exact path='/' component={Home}
              isLoggedIn={this.state.isLoggedIn}
              articlesData={this.state.articlesData}
              categoriesData={this.state.categoriesData}
              productsData={this.state.productsData}
              brandsData={this.state.brandsData}
              />

              <ProtectedRoute path='/profile' component={Profile}
              isLoggedIn={this.state.isLoggedIn}
              setLoggedOut={this.setLoggedOut}
              />

              <ProtectedRoute path='/articles/:articleId/edit' component={ArticleEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              articlesData={this.state.articlesData}
              getArticles={this.getArticles}
              />
              <ProtectedRoute path='/articles' component={Articles} 
              isLoggedIn={this.state.isLoggedIn}
              articlesData={this.state.articlesData}
              getArticles={this.getArticles}
              />
              <ProtectedRoute path='/add-article' component={AddArticle} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              getArticles={this.getArticles}
              />

              <ProtectedRoute path='/products/:productId/edit' component={ProductEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              productsData={this.state.productsData}
              getProducts={this.getProducts}
              getCategories={this.getCategories}
              categoriesData={this.state.categoriesData}
              brandsData={this.state.brandsData}
              />
              <ProtectedRoute path='/products' component={Products} 
              isLoggedIn={this.state.isLoggedIn}
              productsData={this.state.productsData}
              getProducts={this.getProducts}
              />
              <ProtectedRoute path='/add-product' component={AddProduct} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              productsData={this.state.productsData}
              getProducts={this.getProducts}
              getCategories={this.getCategories}
              categoriesData={this.state.categoriesData}
              brandsData={this.state.brandsData}
              />

              <ProtectedRoute path='/categories/:categoryId/edit' component={CategoryEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              categoriesData={this.state.categoriesData}
              getCategories={this.getCategories}
              parentCategoryData={this.state.parentCategoryData}
              getProducts={this.getProducts}
              />
              <ProtectedRoute path='/categories' component={Categories} 
              isLoggedIn={this.state.isLoggedIn}
              categoriesData={this.state.categoriesData}
              getCategories={this.getCategories}
              getProducts={this.getProducts}
              />
              <ProtectedRoute path='/add-category' component={AddCategory} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              categoriesData={this.state.categoriesData}
              getCategories={this.getCategories}
              />

              <ProtectedRoute path='/brands/:brandId/edit' component={BrandEditor}
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              brandsData={this.state.brandsData}
              getBrands={this.getBrands}
              />
              <ProtectedRoute path='/brands' component={Brands} 
              isLoggedIn={this.state.isLoggedIn}
              brandsData={this.state.brandsData}
              getBrands={this.getBrands}
              />
              <ProtectedRoute path='/add-brand' component={AddBrand} 
              isLoggedIn={this.state.isLoggedIn}
              openInfoPopup={this.openInfoPopup}
              handleCloseInfoPopup={this.handleCloseInfoPopup}
              infoData={this.state.infoData}
              isDone={this.state.isDone}
              brandsData={this.state.brandsData}
              getBrands={this.getBrands}
              />

              <Route path="/login">
                <Login handleLogin={this.setLoggedIn} setLoggedIn={this.setLoggedIn} tokenCheck={this.tokenCheck} textError={this.state.textError} errorVisible={this.state.errorVisible} handleSubmitLogin={this.handleSubmitLogin}/>
              </Route>
            </Switch>
            </EdtiorConfigContext.Provider>
          </CurrentUserContext.Provider>
        );
      }
    }
export default withRouter(App);