import React from "react";
import './CategoryEditor.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';

import mainApi from "../../utils/MainApi";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import { translitUrl } from '../../utils/translitUrl/translitUrl';

import infoOk from '../../assets/images/icons/info_ok.svg';
import infoError from '../../assets/images/icons/info_error.svg';

import Button from "../Button/Button";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

import { HelmetProvider, Helmet } from 'react-helmet-async';

class CategoryEditor extends React.Component {
  static contextType = EdtiorConfigContext;
  
  constructor(props){
    super(props);
    this.category = this.props.categoriesData.find(category => category._id === this.props.computedMatch.params.categoryId);
    this.state = ({
      order: this.category.order,
      parentCategory: this.category.parentCategory.length > 0 ? this.category.parentCategory[0]._id : '0',
      additionalCategories: this.category.additionalCategories,
      products: this.category.products,
      isVisible: this.category.isVisible,
      brand: this.category.brand,
      url: this.category.url,
      title: this.category.title,
      description: this.category.description,
      preview: this.category.preview,
      metaTitle: this.category.metaTitle,
      metaDescription: this.category.metaDescription,
      isParentEdited: false,
    })
    
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
    this.handleEditCategorySubmit = this.handleEditCategorySubmit.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.onChangeInEditor = this.onChangeInEditor.bind(this);
  }

  componentDidUpdate() {
    if(this.state.formReset) {
      this._setEditors();
      this.setState({
        formReset: false
      })
    }

    console.log(this.category)
  }
/* ДЛЯ РЕДАКТОРА */
  // componentDidMount() {
  //   this._setEditors();
  // }


  _setEditors() {
    let editorConfig = this.context;
    this.setState({
      editor: <CKEditor
      editor={ Editor }
      config={ editorConfig }
      data={this.state.editorData}
      onReady={ editor => {
          // You can store the 'editor' and use when it is needed.
          console.log( 'Editor is ready to use!', editor );
      } }
      onChange={  ( event, editor ) => this.onChangeInEditor(event, editor)  }
      onBlur={ ( event, editor ) => {
          console.log( 'Blur.', editor );
      } }
      onFocus={ ( event, editor ) => {
          console.log( 'Focus.', editor );
      } }
      />
    })
  }

  handleFormReset() {
    this.setState({
      order: 0,
      title: '',
      description: '',
      preview: '',
      url: '',
      client: '',
      format: '',
      price: '',
      metaTitle: '',
      metaDescription: '',
      formReset: true,
    })
  }

  handlePreviewChange(e) {
    const data = new FormData();
    data.append('upload', e.target.files[0]);
    mainApi.uploadPreview(data)
    .then((res) => {
      this.setState({
        preview: res.url,
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  onChangeInEditor(event, editor) {
    const data = editor.getData();
    this.setState({
      editorData: data
    })
  }

  handleValueChange(e) {
    const {name, value} = e.target;
    if(name === 'title') {
      this.setState({
        title: value,
        url: translitUrl(value),
      })
    } else if(name === 'parentCategory') {
      this.setState({
        isParentEdited: true,
        parentCategory: value,
      })
      
    } else if(name === 'url') {
      this.setState({
        url: value,
      })
    } else {
      this.setState({
        [name]: value,
      })
    }
    
  }

  /** Отправка формы */

  handleEditCategorySubmit(e) {
    e.preventDefault();
    const oldParentCategory = this.category.parentCategory.length > 0 ? this.category.parentCategory[0] : '0';

    mainApi.updateCategory({
      _id: this.category._id,
      order: this.state.order,
      parentCategory: this.state.parentCategory === '0' ? [] : [this.state.parentCategory],
      additionalCategories: this.state.additionalCategories,
      products: this.state.products,
      isVisible: this.state.isVisible,
      brand: this.state.brand,
      url: this.state.url,
      title: this.state.title,
      description: this.state.description,
      preview: this.state.preview,
      metaTitle: this.state.metaTitle,
      metaDescription: this.state.metaDescription
    })
    .then((res) => {
      if(this.state.isParentEdited) {
        const newCategory = this.state.parentCategory === '0' ? '0' : this.state.parentCategory;

        if(oldParentCategory !== '0') {
          mainApi.updateCategoryAdditional(oldParentCategory._id, oldParentCategory.additionalCategories.map(c => {
            if(c !== this.category._id) {
              return c
            }
          }))
          .then((res) => {
            if(newCategory !== '0') {
              const updatedCategory = this.props.categoriesData.find(category => category._id === newCategory);
              mainApi.updateCategoryAdditional(updatedCategory._id, updatedCategory.additionalCategories.concat(this.category._id))
              .then((res) => {
                this.props.openInfoPopup({
                  text: `Категория перемещена в ${res.title}`,
                  path: '/categories',
                  img: infoOk
                });
                this.props.getCategories();
              })
              .catch((err) => {
                console.log(err);
                this.props.openInfoPopup({
                  text: `Произошла какая то ошибка при перемещении категории в ${updatedCategory.title}`,
                  path: window.location.pathname,
                  img: infoError
                });
              });
            } else {
              mainApi.updateParentCategory({
                additionalCategories: this.props.parentCategoryData.additionalCategories.map(c => {
                  if(c !== this.category._id) {
                    return c
                  }
                }),
                title: this.props.parentCategoryData.title,
                description: this.props.parentCategoryData.description,
                metaTitle: this.props.parentCategoryData.metaTitle,
                metaDescription: this.props.parentCategoryData.metaDescription
              })
              .then((res) => {
                this.props.openInfoPopup({
                  text: `Категория перемещена в Каталог`,
                  path: '/categories',
                  img: infoOk
                });
                this.props.getCategories();
              })
              .catch((err) => {
                console.log(err);
                this.props.openInfoPopup({
                  text: `Произошла какая то ошибка при перемещении категории в Каталог`,
                  path: window.location.pathname,
                  img: infoError
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.props.openInfoPopup({
              text: `Произошла какая то ошибка при удалении категории из ${oldParentCategory.title}`,
              path: window.location.pathname,
              img: infoError
            });
          });

      } else {
        mainApi.getParentCategory().then((res) => {
          if(res) {
            mainApi.updateParentCategory({
              additionalCategories: res.additionalCategories.map(c => {
                if(c !== this.category._id) {
                  return c
                }
              }),
              title: res.title,
              description: res.description,
              metaTitle: res.metaTitle,
              metaDescription: res.metaDescription
            }).then((res) => {
              if(newCategory !== '0') {
                const updatedCategory = this.props.categoriesData.find(category => category._id === newCategory);
                mainApi.updateCategoryAdditional(updatedCategory._id, updatedCategory.additionalCategories.concat(this.category._id))
                .then((res) => {
                  this.props.openInfoPopup({
                    text: `Категория перенесена в ${res.title}`,
                    path: '/categories',
                    img: infoOk
                  });
                  this.props.getCategories();
                })
                .catch((err) => {
                  console.log(err);
                  this.props.openInfoPopup({
                    text: `Произошла какая то ошибка при перенесении категории в ${updatedCategory.title}`,
                    path: window.location.pathname,
                    img: infoError
                  });
                });
              }
            })
            .catch((err) => {
              this.props.openInfoPopup({
                text: 'Произошла какая то ошибка при изменении каталога!',
                path: window.location.pathname,
                img: infoError
              });
              console.log(err);
            });
          } else {
            this.props.openInfoPopup({
              text: 'Категория изменена!',
              path: '/categories',
              img: infoOk
            });
            this.props.getCategories();
          }
        })
        .catch((err) => {
          this.props.openInfoPopup({
            text: 'Произошла какая то при получении домашней категории!',
            path: window.location.pathname,
            img: infoError
          });
          console.log(err);
        });
      }

      }
    })
    .then((res) => {
      this.props.openInfoPopup({
        text: 'Категория изменена!',
        path: '/categories',
        img: infoOk
      });
      this.props.getCategories();
    })
    .catch((err) => {
      console.log(err);
      this.props.openInfoPopup({
        text: 'Произошла какая то ошибка!',
        path: window.location.pathname,
        img: infoError
      });
    });
  }


  render() {
    return(
      <>
      <HelmetProvider><Helmet>
      <title>Редактировать категорию</title>
    </Helmet></HelmetProvider>
      <Header />
      <main className="portfolio-editor">
        <div className="portfolio-editor__form-container">
          <h1 className="section__header">Редактировать категорию</h1>
          <form className="form portfolio-editor__form" id='portfolio-editor-form' onSubmit={this.handleEditCategorySubmit} noValidate={true}>

          <div className='form__field form__field_big'>
            <label className='form__label' htmlFor='title'>Название</label>
            <input id='title' className='form__input' required name='title' type='text' placeholder='Название' value={this.state.title} onChange={this.handleValueChange} />
            <span className="title-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='description'>Краткое описание</label>
            <input id='description' className='form__input' required name='description' type='text' placeholder='description' value={this.state.description} onChange={this.handleValueChange} />
            <span className="description-error error-message"></span>
          </div>

          <div className='form__field'>
            <label className='form__label form__field_small' htmlFor='url'>URL</label>
            <input id='url' className='form__input' required name='url' type='text' placeholder='url' value={this.state.url} onChange={this.handleValueChange} />
            <span className="url-error error-message"></span>
          </div>

          <div className='form__field form__field_big'>
            <label className='form__label' htmlFor='parentCategory'>Родительская категория</label>
            <select className='form__input' value={this.state.parentCategory} onChange={this.handleValueChange} multiple={false} id="parentCategory" name="parentCategory">
              <option value='0'>Каталог</option>
              {
                this.props.categoriesData.map(c => <option value={c._id} key={c._id}>{`${c.title}(${Array.isArray(c.parentCategory) && c.parentCategory.length > 0 ? c.parentCategory[0].title : 'Каталог'})`}</option>)
              }
            </select>
            <span className="additionalCategories-error error-message"></span>
          </div>

          {/* <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='additionalCategories'>Дополнительные категории</label>
            <select className='form__input' id="additionalCategories" name="additionalCategories">
              <option value="">-- Выберите категорию --</option>
              <option value="petersburg">Санкт-Петербург</option>
              <option value="samara">Самара</option>
              <option value="perm">Пермь</option>
              <option value="novosibirsk">Новосибирск</option>
            </select>
            <span className="additionalCategories-error error-message"></span>
          </div> */}

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='order'>Сортировка</label>
             <input id='order' className='form__input' required name='order' type='number' placeholder='Сортировка' value={this.state.order} onChange={this.handleValueChange} />
            <span className="order-error error-message"></span>
          </div>
          
          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='isVisible'>Видимость</label>
            <select className='form__input' value={this.state.isVisible} onChange={this.handleValueChange} multiple={false} id="isVisible" name="isVisible">
              <option value={true}>Видимый</option>
              <option value={false}>Скрыть</option>
            </select>
            <span className="isVisible-error error-message"></span>
          </div>

          <div className="form__field form__field_small">
            <img className='portfolio-editor__image-field' src={this.state.preview} alt='Превью'/>
          </div>

          <div className='form__field'>
            <div>
              <label className='form__label form__file-label portfolio-editor__file-label' htmlFor='preview'>Превью</label>
              <input id='preview' className='form__input form__file-input' required name='preview' type='file' placeholder='preview' onChange={this.handlePreviewChange}/>
              <span className="preview-error error-message"></span>
            </div>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaTitle'>Мета-title</label>
            <textarea id='metaTitle' className='form__textarea' required name='metaTitle' placeholder='metaTitle' value={this.state.metaTitle} onChange={this.handleValueChange} />
            <span className="metaTitle-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaDescription'>Мета-description</label>
            <textarea id='metaDescription' className='form__textarea' required name='metaDescription' placeholder='metaDescription' value={this.state.metaDescription} onChange={this.handleValueChange} />
            <span className="metaDescription-error error-message"></span>
          </div>

          {/* <div className='ck-editor__field'>
            <label className='form__label portfolio-editor__form-label'>Описание</label>
            {this.state.editor}
          </div> */}
          <div className='add-article__button-container'>
            <Button type='reset' onClick={this.handleFormReset} className='section__button_transparent add-article__button add-article__form-reset-button' disabled={false}>
              Сбросить
            </Button>
            <Button type='submit' className='form__button' disabled={false}>
              Сохранить
            </Button>
          </div>

          </form>
        </div>
        <InfoTooltip
        isDone={this.props.isDone}
        handleCloseInfoPopup={this.props.handleCloseInfoPopup}
        path={this.props.infoData.path}
        img={this.props.infoData.img}
        text={this.props.infoData.text}
      />
      </main>
      <Footer />
      </>

      )
  }
}

export default CategoryEditor;