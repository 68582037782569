import React, { useEffect, useRef } from 'react';
import './AddArticle.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { EdtiorConfigContext } from '../../contexts/EdtiorConfigContext';
import mainApi from '../../utils/MainApi';
import { translitUrl } from '../../utils/translitUrl/translitUrl';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Button from '../Button/Button';

import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { useHistory } from 'react-router-dom';

import { HelmetProvider, Helmet } from 'react-helmet-async';

import infoOk from '../../assets/images/icons/info_ok.svg';

function AddArticle(props) {
  // Подписка на контекст
  const editorConfig = React.useContext(EdtiorConfigContext);

  // History я InfoToolTip
  const history = useHistory();

  const [isVisible, setIsVisible] = React.useState(true);
  const [category, setCategory] = React.useState('Новости');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [tags, setTags] = React.useState('новости');
  const [metaTitle, setMetaTitle] = React.useState('');
  const [metaDescription, setMetaDescription] = React.useState('');
  const [editor, setEditor] = React.useState(null);
  const [previewArticle, setPreviewArticle] = React.useState('');
  const [editorData, setEditorData] = React.useState('');


  function handleVisibleChange(e) {
    setIsVisible(e.target.value);
  }

  function handleCategoryChange(e) {
    setCategory(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
    setUrl(translitUrl(e.target.value));
  }
  
  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  function handleTagsChange(e) {
    setTags(e.target.value);
  }

  function handleMetaTitleChange(e) {
    setMetaTitle(e.target.value);
  }

  function handleMetaDescriptionChange(e) {
    setMetaDescription(e.target.value);
  }

  function handleFormReset() {
    setIsVisible(true);
    setCategory('');
    setTitle('');
    setDescription('');
    setUrl('');
    setTags('');
    setMetaTitle('');
    setMetaDescription('');
    setPreviewArticle('')
    setEditorData('');
  }

  /** Отправка формы */

  function handleAddArticleSubmit(e) {
    e.preventDefault();
    mainApi.createArticle({
      isVisible: isVisible,
      category: category,
      title: title,
      description: description,
      preview: previewArticle,
      url: url,
      tags: tags.split(';'),
      htmlCode: editorData,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    })
    .then((res) => {
      props.openInfoPopup({
        text: 'Статья добавлена!',
        path: '/articles',
        img: infoOk
      });
      props.getArticles();
    })
    .catch((err) => {
      console.log(err);
    });
  }

  function handlePreviewChange(e) {
    const data = new FormData();
    data.append('upload', e.target.files[0]);
    mainApi.uploadPreview(data)
    .then((res) => {
      setPreviewArticle(res.url);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  function onChangeInEditor(event, editor) {
    const data = editor.getData();
    setEditorData(data);
  }

  React.useEffect(() => {
    setEditor(
      <CKEditor
        editor={ Editor }
        config={ editorConfig }
        data={editorData}
        onReady={ editor => {
            // You can store the 'editor' and use when it is needed.
            console.log( 'Editor is ready to use!', editor );
        } }
        onChange={  ( event, editor ) => onChangeInEditor(event, editor)  }
        onBlur={ ( event, editor ) => {
            console.log( 'Blur.', editor );
        } }
        onFocus={ ( event, editor ) => {
            console.log( 'Focus.', editor );
        } }
        />
    )
  }, [editorData])

  

  return (
    <>
    <HelmetProvider><Helmet>
      <title>Добавить статью</title>
    </Helmet></HelmetProvider>
    <Header />
    <main className='add-article'>
      <div className='add-article__form-container'>
        <h1 className='section__header'>Добавить статью</h1>
        <form className='form add-article__form' id='add-article-form' onSubmit={handleAddArticleSubmit} noValidate={true}>

        <div className='form__field form__field_big visibleRadio'>
            <label className='form__label'>Видимость</label>
            <div className='form__radio'>
              <div className='radio__input'>
                <input type="radio" id="visible" name="isVisible" defaultChecked={true} value={true} onChange={handleVisibleChange}/>
                <label htmlFor="visible">Показывается</label>
              </div>
              <div className='radio__input'>
                <input type="radio" id="noVisible" name="isVisible" value={false} onChange={handleVisibleChange}/>
                <label htmlFor="noVisible">Скрыто</label>
              </div>
            </div>
        </div>

        

          <div className='form__field form__field_big'>
            <label className='form__label' htmlFor='title'>Заголовок</label>
            <input id='title' className='form__input' required name='title' type='text' placeholder='title' value={title} onChange={handleTitleChange} />
            <span className="title-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='category'>Категория</label>
            <input id='category' className='form__input' required name='category' type='text' placeholder='Категория' value={category} onChange={handleCategoryChange} />
            <span className="category-error error-message"></span>
          </div>

          <div className='form__field'>
            <label className='form__label form__field_small' htmlFor='url'>URL</label>
            <input id='url' className='form__input' required name='url' type='text' placeholder='url' defaultValue={url} />
            <span className="url-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='description'>Краткое описание</label>
            <textarea id='description' className='form__textarea' required name='description' type='text' placeholder='description' value={description} onChange={handleDescriptionChange} />
            <span className="description-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='tags'>Теги</label>
            <input id='tags' className='form__input' required name='tags' type='text' placeholder='tags' value={tags} onChange={handleTagsChange} />
            <span className="tags-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaTitle'>Мета-title</label>
            <textarea id='metaTitle' className='form__textarea' required name='metaTitle' placeholder='metaTitle' value={metaTitle} onChange={handleMetaTitleChange} />
            <span className="metaTitle-error error-message"></span>
          </div>

          <div className='form__field form__field_small'>
            <label className='form__label' htmlFor='metaDescription'>Мета-description</label>
            <textarea id='metaDescription' className='form__textarea' required name='metaDescription' placeholder='metaDescription' value={metaDescription} onChange={handleMetaDescriptionChange} />
            <span className="metaDescription-error error-message"></span>
          </div>

          <div className="form__field form__field_small">
            {previewArticle && <img className='portfolio-editor__image-field' src={previewArticle} alt='Превью'/>}
          </div>

          <div className='form__field'>
            <label className='form__label form__file-label add-article__file-label' htmlFor='preview'>Превью</label>
            <input id='preview' className='form__input form__file-input' required name='preview' type='file' placeholder='preview' onChange={handlePreviewChange}/>
            <span className="preview-error error-message"></span>
          </div>

          <div className='ck-editor__field'>
            {editor}
          </div>
          <div className='add-article__button-container'>
            <Button type='reset' onClick={handleFormReset} className='section__button_transparent add-article__button add-article__form-reset-button' disabled={false}>
              Сбросить
            </Button>
            <Button type='submit' className='form__button' disabled={false}>
              Сохранить
            </Button>
          </div>
        </form>
      </div>
      <InfoTooltip
        isDone={props.isDone}
        handleCloseInfoPopup={props.handleCloseInfoPopup}
        history={history}
        path={props.infoData.path}
        img={props.infoData.img}
        text={props.infoData.text}
      />
    </main>
    <Footer />
    </>

  );
}

export default AddArticle;